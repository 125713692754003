"use client";

import { useImageConfiguration } from "@/contexts/image-configuration/image-configuration.context";
import type { ArticleTeaserModel } from "@/services/api/content/models/article-teaser-model.types";
import { tailwindScreenSize } from "@/utilities/get-tailwind-config";
import { imageLoader } from "@/utilities/image-loader";
import clsx from "clsx";
import NextImage from "next/image";
type BackgroundImageProps = {
  readonly supportingImage: ArticleTeaserModel.SupportingImage | null;
  readonly hasImagePriority?: boolean;
  readonly sourceSetSize?: string;
  readonly className?: string;
};
export function BackgroundImage({
  className,
  hasImagePriority = false,
  sourceSetSize = `(max-width: ${tailwindScreenSize.md}) 100vw, 964px`,
  supportingImage
}: BackgroundImageProps) {
  const {
    serverUrl
  } = useImageConfiguration();
  if (supportingImage === null) {
    return null;
  }
  return <div className="absolute h-full w-full" data-sentry-component="BackgroundImage" data-sentry-source-file="BackgroundImage.component.tsx">
      <NextImage alt={supportingImage.alternativeText} className={clsx("select-none bg-whisper object-cover object-center", className)} draggable={false} fill={true} loader={imageLoader(serverUrl)} priority={hasImagePriority} sizes={sourceSetSize} src={supportingImage.aspectRatio16By9} data-sentry-element="NextImage" data-sentry-source-file="BackgroundImage.component.tsx" />
    </div>;
}