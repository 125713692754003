"use client";

import { Link } from "@/components/Link/Link.component";
import type { LinkProps } from "@/components/Link/Link.component";
import { useSnowplowClickEvent } from "@/hooks/tracking/snowplow/use-snowplow-click-event.hook";
type SnowplowLinkProps = LinkProps & {
  readonly trackingModel: {
    readonly url: string;
    readonly trackingId: null | string;
    readonly articleId: number;
  };
};
export function SnowplowLink({
  children,
  className,
  href,
  trackingModel,
  ...rest
}: SnowplowLinkProps) {
  const {
    articleId,
    trackingId,
    url
  } = trackingModel;
  const trackingData = {
    // id and classes have been removed for now as they are not used
    classes: [],
    contentId: articleId,
    // id and classes have been removed for now as they are not used
    id: "",
    trackingId: trackingId ?? null,
    url
  };
  const {
    clickCallback,
    keypressCallback
  } = useSnowplowClickEvent(trackingData);
  return <Link className={className} href={href} {...rest} onKeyDown={keypressCallback} onMouseDown={clickCallback} data-sentry-element="Link" data-sentry-component="SnowplowLink" data-sentry-source-file="SnowplowLink.component.tsx">
      {children}
    </Link>;
}