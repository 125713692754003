"use client";

import type { ReactNode } from "react";
import { createContext, useContext } from "react";
const ImageConfigurationContext = createContext<ImageConfiguration | undefined>(undefined);
export function useImageConfiguration(): ImageConfiguration {
  const imageConfiguration = useContext(ImageConfigurationContext);
  if (imageConfiguration === undefined) {
    // If you reach this statement, you have discovered a programming error :)
    throw new Error("An `ImageConfigurationProvider` must exist in the component hierarchy.");
  }
  return imageConfiguration;
}
type ImageConfiguration = {
  readonly serverUrl: string;
};
type ImageConfigurationProviderProps = {
  readonly children?: ReactNode;
  readonly imageConfiguration: ImageConfiguration;
};
export function ImageConfigurationProvider({
  children,
  imageConfiguration
}: ImageConfigurationProviderProps) {
  return <ImageConfigurationContext.Provider value={imageConfiguration} data-sentry-element="unknown" data-sentry-component="ImageConfigurationProvider" data-sentry-source-file="image-configuration.context.tsx">
      {children}
    </ImageConfigurationContext.Provider>;
}